/*=========================================================================================
  File Name: moduleReservationMutations.js
  Description: Reservation Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    SET_RESERVATIONS_FROM_WEB(state, reservationFromWeb) {
        state.reservationFromWeb = reservationFromWeb
    },
    SET_ETABLISSEMENT(state, etablissement) {
        state.etablissement = etablissement
    },
    SET_RESERVATIONS(state, reservations) {
        state.reservations = reservations
    },
    SET_RESERVATION(state, reservation) {
        state.reservation = reservation
    },
    SET_RESERVATION_STATUS_LIST(state, reservationStatusList) {
        state.reservationStatusList = reservationStatusList
    },
    SET_PRODUCTSHEETS_RESERVATION_CALENDAR(state, prodsheetReservationCalendar) {
        state.prodsheetReservationCalendar = prodsheetReservationCalendar
    },
    UPDATE_PRODUCT_RESERVATION(state, item) {
        const index = state.reservation.reservation_product.findIndex((p) => p.uid === item.uuid)
        state.reservation.reservation_product[index].productsheet = {}
        Object.assign(state.reservation.reservation_product[index].productsheet, item.productsheet)
    },
    REMOVE_RECORD(state, itemId) {
        const reservationFromWebIndex = state.reservationFromWeb.findIndex((u) => u.id === itemId)
        state.reservationFromWeb.splice(reservationFromWebIndex, 1)
    },
    UPDATE_PAYMENT_RESERVATION_CONFIRMED_COL(state, payload) {
        let findItem = state.reservation.payments.find((p) => p.reference === payload.reference)
        findItem.confirmed = payload.confirmed
        Object.assign(state.reservation.payments, findItem)
    },
    SET_Delivery_List(state, deliveries) {
        state.Deliveries = deliveries
    }
}