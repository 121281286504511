/*=========================================================================================
  File Name: moduleReservationActions.js
  Description: Reservation Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

export default {
    sendMailContactForm({ commit }, payload) {
        return new Promise((resolve, reject) => {
            payload.loading()
            axios.post(`/api/v4/client/send-contact-form`, payload.contactForm)
                .then((response) => {
                    payload.loading.close()
                    payload.notify({
                        time: 8000,
                        title: 'Succès',
                        text: 'Email a été envoyé avec succès',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success',
                        position: 'top-right'
                    })
                    resolve(response)
                })
                .catch((error) => {
                    payload.loading.close()
                    let errors = Object.values(error.response.data.errors)
                    errors.forEach(error => 
                      payload.notify({
                        time: 8000,
                        title: 'Erreur',
                        text: error[0],
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger',
                        position:'top-right'
                      })
                    );
                    reject(error)
                })
        })
    },
}