/*=========================================================================================
  File Name: moduleReservationManagement.js
  Description: Reservation Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleReservationManagementState.js'
import mutations from './moduleReservationManagementMutations.js'
import actions from './moduleReservationManagementActions.js'
import getters from './moduleReservationManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

