/*=========================================================================================
  File Name: moduleReservationState.js
  Description: Reservation Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    reservationFromWeb: [],
    reservations: [],
    reservation: [],
    reservationStatusList: [],
    etablissement: [],
    Deliveries: []
}